import { render, staticRenderFns } from "./page-title.vue?vue&type=template&id=5cfdeb3f&scoped=true"
import script from "./page-title.vue?vue&type=script&lang=js"
export * from "./page-title.vue?vue&type=script&lang=js"
import style0 from "./page-title.vue?vue&type=style&index=0&id=5cfdeb3f&prod&lang=scss&scoped=scoped"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cfdeb3f",
  null
  
)

export default component.exports